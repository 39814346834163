import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: [],
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    DELETE(state, id) {
      const ItemIndex = state.respData.data.findIndex(data => data._id === id)
      state.respData.data.splice(ItemIndex, 1)
    },
    UPDATE(state, item) {
      const productIndex = state.respData.data.findIndex(data => data._id === item._id)
      Object.assign(state.respData.data[productIndex], item)
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/privileges/get?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response.data)
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/privileges/add', item)
          .then(response => {
            console.log(response.data.data)
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/root/privileges/delete/${item}`)
          .then(response => {
            commit('DELETE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/root/privileges/update/${item._id}`, item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
