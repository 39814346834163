<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('ADD_NEW') : $t('UPDATE') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <!-- image -->
            <b-col cols="12">
              <b-form-group
                :label="$t('IMAGE')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t('Upload') }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t('Reset') }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                      {{ $t('Max size of') }} 300kB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>

            <!-- name -->
            <b-col cols="12">
              <b-form-group
                :label="$t('NAME')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('NAME')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- point -->
            <b-col cols="12">
              <b-form-group
                :label="$t('Point')"
                label-for="point"
              >
                <validation-provider
                  #default="{ errors }"
                  name="point"
                  rules="required"
                >
                  <b-form-input
                    id="point"
                    v-model="point"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Point')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- amount -->
            <b-col cols="12">
              <b-form-group
                :label="$t('Amount')"
                label-for="amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    v-model="amount"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Amount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- option (vue-select) -->
            <b-col md="6">
              <b-form-group
                :label="$t('Option')"
                label-for="option"
              >
                <validation-provider
                  #default="{ errors }"
                  name="option"
                  rules="required"
                >
                  <v-select
                    id="option"
                    v-model="option"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="optionData"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <!-- type (vue-select) -->
            <b-col md="6">
              <b-form-group
                :label="$t('Type')"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <v-select
                    id="type"
                    v-model="type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typeData"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

            <!-- detail -->
            <b-col cols="12">
              <b-form-group
                :label="$t('Detail')"
                label-for="detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="detail"
                  rules="required"
                >
                  <b-form-input
                    id="detail"
                    v-model="detail"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- limit -->
            <b-col cols="12">
              <b-form-group
                :label="$t('Limit')"
                label-for="limit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="limit"
                  rules="required"
                >
                  <b-form-input
                    id="limit"
                    v-model="limit"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Limit')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- minimumPrice -->
            <b-col cols="12">
              <b-form-group
                :label="$t('minimumPrice')"
                label-for="minimumPrice"
              >
                <validation-provider
                  #default="{ errors }"
                  name="minimumPrice"
                  rules="required"
                >
                  <b-form-input
                    id="minimumPrice"
                    v-model="minimumPrice"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('minimumPrice')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- startDate -->
            <b-col cols="6">
              <b-form-group
                :label="$t('startDate')"
                label-for="startDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="startDate"
                  rules="required"
                >
                  <flat-pickr
                    id="startDate"
                    v-model="startDate"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('startDate')"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- expireDate -->
            <b-col cols="6">
              <b-form-group
                :label="$t('expireDate')"
                label-for="expireDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="expireDate"
                  rules="required"
                >
                  <flat-pickr
                    id="expireDate"
                    v-model="expireDate"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('expireDate')"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ dataId !== null ? $t('Edit') : $t('Add') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import {
//   required, length,
// } from '@validations'

const STORE_MODULE_NAME = 'privileges'

export default {
  components: {
    vSelect,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    flatPickr,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      name: '',
      point: '',
      amount: '',
      option: '',
      optionData: [
        { value: 'constant', text: 'Constant' },
        { value: 'percent', text: 'Percent' },
      ],
      type: '',
      typeData: [
        { value: 'coupon', text: 'Coupon' },
        { value: 'promotion', text: 'Promotion' },
      ],
      detail: '',
      limit: '',
      minimumPrice: '',
      startDate: '',
      expireDate: '',
      required,
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
            image,
            name,
            point,
            amount,
            option,
            type,
            detail,
            limit,
            minimumPrice,
            startDate,
            expireDate,
          } = this.data
          this.dataId = _id
          this.image = image
          this.name = name
          this.point = point
          this.amount = amount
          this.option = option
          if (option === 'constant') {
            this.option = { value: 'constant', text: 'Constant' }
          } else {
            this.option = { value: 'percent', text: 'Percent' }
          }
          this.type = type
          if (type === 'coupon') {
            this.type = { value: 'coupon', text: 'Coupon' }
          } else {
            this.type = { value: 'promotion', text: 'Promotion' }
          }
          this.detail = detail
          this.limit = limit
          this.minimumPrice = minimumPrice
          this.startDate = startDate
          this.expireDate = expireDate
        }
      }
    },
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.showToast(
            'danger',
            'bottom-right',
            'ขนาดไฟล์ไม่เกิน 300KB',
            'XCircleIcon',
            'กรุณาตรวจสอบรูปภาพ',
          )
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          console.log(this.image)
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = ''
      this.point = ''
      this.amount = ''
      this.option = ''
      this.type = ''
      this.detail = ''
      this.limit = ''
      this.minimumPrice = ''
      this.startDate = ''
      this.expireDate = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            image: this.image,
            name: this.name,
            point: this.point,
            amount: this.amount,
            option: this.option.value,
            type: this.type.value,
            detail: this.detail,
            limit: this.limit,
            minimumPrice: this.minimumPrice,
            startDate: this.startDate,
            expireDate: this.expireDate,
          }
          console.log(obj)
          if (this.dataId !== null) {
            store
              .dispatch(`${STORE_MODULE_NAME}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                console.log('fetch Success : ', result)
                this.showToast(
                  'success',
                  'bottom-right',
                  result.data.message,
                  'CheckIcon',
                  'แก้ไขข้อมูลสำเร็จ',
                )
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'bottom-right',
                  error.data.message,
                  'XCircleIcon',
                  'แก้ไขข้อมูลไม่สำเร็จ',
                )
              })
          } else {
            delete obj._id
            store
              .dispatch(`${STORE_MODULE_NAME}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                console.log(
                  'fetch Success : ',
                  result.data.message,
                  'check-circle',
                )
                this.showToast(
                  'success',
                  'bottom-right',
                  result.data.message,
                  'check-circle',
                  'เพิ่มข้อมูลสำเร็จ',
                )
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'bottom-right',
                  error.data.message,
                  'XCircleIcon',
                  'เพิ่มข้อมูลไม่สำเร็จ',
                )
              })
          }
        }
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
