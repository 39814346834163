<template>
  <div>
    <sidebar
      :is-sidebar-active.sync="isSidebarActive"
      :data="sidebarData"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <label>{{ $t('SHOW') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector v-select-custom"
              @input="pageSize"
            />
            <label>{{ $t('ENTRIES') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search') + '...'"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew()"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="respData"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Key -->
        <!-- <template #cell(key)="data">
          <span class="align-middle ml-50">{{ data.item.key }}</span>
        </template> -->

        <!-- Column: Image -->
        <template #cell(image)="data">
          <!-- <span class="align-middle ml-50">{{ data.item.image }}</span> -->
          <b-img
            v-if="data.item.image"
            ref="previewEl"
            rounded
            :src="data.item.image"
            height="80"
          />
          <b-img
            v-else
            ref="previewEl"
            :src="require('@/assets/images/blank/no_image.png')"
            rounded
            height="80"
          />
        </template>

        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <!-- Edit & Delete -->
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="sidebarEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('EDIT') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deletePrivileges(data.item._id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >{{ currentPage * perPage - (perPage - 1) }} -
              {{
                queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
              }}
              {{ $t('of') }} {{ queriedItems }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="queriedItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="nextPage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BFormInput,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import memberStoreModule from '../../../store/root/privileges'
import Sidebar from './PrivilegesSidebar.vue'

const STORE_MODULE_NAME = 'privileges'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 50,
      isSidebarActive: false,
      perPageOptions: [50, 100, 300, 500, 1000],
      sortBy: ref('_id'),
      sidebarData: {},
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'image', label: this.$t('IMAGE'), sortable: false },
        { key: 'name', label: this.$t('NAME'), sortable: true },
        { key: 'point', label: this.$t('Point'), sortable: true },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        { key: 'option', label: this.$t('Option'), sortable: true },
        { key: 'type', label: this.$t('Type'), sortable: true },
        { key: 'detail', label: this.$t('Detail'), sortable: true },
        { key: 'limit', label: this.$t('Limit'), sortable: true },
        { key: 'minimumPrice', label: this.$t('minimumPrice'), sortable: true },
        { key: 'startDate', label: this.$t('startDate'), sortable: true },
        { key: 'expireDate', label: this.$t('expireDate'), sortable: true },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state.privileges.respData.data
    },
    queriedItems() {
      return store.state.privileges.respData.max || 50
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, memberStoreModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      .then(result => {
        console.log('fetch Success : ', result)
        this.showToast(
          'success',
          'bottom-right',
          result.data.message,
          'CheckIcon',
          'เรียกข้อมูลสำเร็จ',
        )
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.showToast(
          'danger',
          'bottom-right',
          error.data.message,
          'XCircleIcon',
          'เรียกข้อมูลไม่สำเร็จ',
        )
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  methods: {
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deletePrivileges(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              .then(result => {
                this.show = false
                console.log('fetch Success : ', result)
                this.showToast(
                  'success',
                  'top-right',
                  result.data.message,
                  'CheckIcon',
                  'ลบข้อมูลสำเร็จ',
                )
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.showToast(
                  'danger',
                  'top-right',
                  error.data.message,
                  'XCircleIcon',
                  'ลบข้อมูลไม่สำเร็จ',
                )
              })
          } else {
            this.showToast(
              'warning',
              'top-right',
              'Delete cancel!',
              'AlertCircleIcon',
              'ยกเลิกการลบข้อมูล',
            )
          }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      this.show = true
      store
        .dispatch('privileges/get', obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.showToast(
            'danger',
            'top-right',
            'FetchUsers Error ',
            'XCircleIcon',
            'เรียกข้อมูลไม่สำเร็จ',
          )
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.showToast(
            'danger',
            'top-right',
            'FetchUsers Error ',
            'XCircleIcon',
            'เรียกข้อมูลไม่สำเร็จ',
          )
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
